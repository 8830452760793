class orderStatusComponent extends Component {

    static name() {
        return "orderStatusComponent";
    }

    static componentName() {
        return "orderStatusComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    getTemplate() {
        return `<span v-bind:class="{badge:true,'badge-success':data.Status=='1','badge-danger':data.Status!='1'}">
                    <template v-if="data.Status=='1'">
                        {{tr('Approved')}}
                    </template>
                    <template v-else>
                        {{tr('Not Approved')}}
                    </template>
               </span>`;
    }
}

orderStatusComponent.registerComponent();